import {createAction} from 'redux-api-middleware';
import {USER_API_URL} from '../../constants/envVariables';
import {
  Users_FAILURE,
  Users_REQUEST,
  Users_SUCCESS,
  UsersDefined_REQUEST,
  UsersDefined_SUCCESS,
  UsersDefined_FAILURE,
  EnableUser_FAILURE,
  DisableUser_REQUEST,
  DisableUser_FAILURE,
  DisableUser_SUCCESS,
  EnableUser_REQUEST,
  EnableUser_SUCCESS,
  ORGCODE_REQUEST,
  ORGCODE_SUCCESS,
  ORGCODE_FAILURE,
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETDEVICE_FAILURE,
  RESETDEVICE_SUCCESS,
  RESETDEVICE_REQUEST,
  CHECKPORTALACCESS_REQUEST,
  CHECKPORTALACCESS_SUCCESS,
  CHECKPORTALACCESS_FAILURE,
  UPDATEACCESS_REQUEST,
  UPDATEACCESS_SUCCESS,
  UPDATEACCESS_FAILURE,
  ADDMANAGER_REQUEST,
  ADDMANAGER_SUCCESS,
  ADDMANAGER_FAILURE,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
} from './constants';

export const getUserDetails = (accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/Self`,
    method: 'GET',
    types: [Users_REQUEST, Users_SUCCESS, Users_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const resetData = (type) => {
  return createAction({
    types: [type],
  });
};

export const getUsersDefined = (accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/GetUsers`,
    method: 'GET',
    types: [UsersDefined_REQUEST, UsersDefined_SUCCESS, UsersDefined_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const enableUser = (id, accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/EnableUser`,
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      EnableUser_REQUEST,
      {
        type: EnableUser_SUCCESS,
        payload: {id},
      },
      EnableUser_FAILURE,
    ],
  });
};
export const disableUser = (id, accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/DisableUser`,
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      DisableUser_REQUEST,
      {
        type: DisableUser_SUCCESS,
        payload: {id},
      },
      DisableUser_FAILURE,
    ],
  });
};
export const getOrgCode = (accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/GetOrgCode`,
    method: 'GET',
    types: [ORGCODE_REQUEST, ORGCODE_SUCCESS, ORGCODE_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const addRegionManager = (accessToken, obj, rowName) => {
  if (rowName === 'regionList') {
    return createAction({
      endpoint: `${USER_API_URL}/Hierarchy/AddRegionManager`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else if (rowName === 'branchList') {
    return createAction({
      endpoint: `${USER_API_URL}/Hierarchy/AddBranchManager`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};

export const deleteRegionManager = (accessToken, obj, rowName) => {
  if (rowName === 'regionList') {
    return createAction({
      endpoint: `${USER_API_URL}/Hierarchy/DeleteRegionManagerMapping`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else if (rowName === 'branchList') {
    return createAction({
      endpoint: `${USER_API_URL}/Hierarchy/DeleteBranchManagerMapping`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};

export const resetUserPassword = (accessToken, userId) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/AdminChangeUserPassword?userId=${userId}`,
    method: 'GET',
    types: [
      RESETPASSWORD_REQUEST,
      RESETPASSWORD_SUCCESS,
      RESETPASSWORD_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const resetDevice = (userId, accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/Device/ResetDevices`,
    method: 'POST',
    body: JSON.stringify({userId}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      RESETDEVICE_REQUEST,
      {
        type: RESETDEVICE_SUCCESS,
        payload: {userId},
      },
      RESETDEVICE_FAILURE,
    ],
  });
};
export const updateAccess = (
  userId,
  accessToken,
  canAccessApp,
  canAccessPortal,
) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/UpdatePortalOrAppAccess`,
    method: 'POST',
    body: JSON.stringify({userId, canAccessApp, canAccessPortal}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      UPDATEACCESS_REQUEST,
      {
        type: UPDATEACCESS_SUCCESS,
        payload: {userId, canAccessApp, canAccessPortal},
      },
      UPDATEACCESS_FAILURE,
    ],
  });
};

export const checkPortalAccess = (username) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/CheckPortalAccess?Username=${username}`,
    method: 'GET',
    types: [
      CHECKPORTALACCESS_REQUEST,
      CHECKPORTALACCESS_SUCCESS,
      CHECKPORTALACCESS_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};
export const getUsersData = (accessToken) => {
  return createAction({
    endpoint: `${USER_API_URL}/User/GetUsersDownload`,
    method: 'GET',
    types: [USER_DATA_REQUEST, USER_DATA_SUCCESS, USER_DATA_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
