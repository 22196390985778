import {Table, message, Modal, Button, Switch, Select} from 'antd';
import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {userInfoColumns} from '../../data/userInfo';
import {useSelector, useDispatch} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {
  getUsersDefined,
  enableUser,
  disableUser,
  resetUserPassword,
  resetDevice,
  updateAccess,
  addRegionManager,
  deleteRegionManager,
  resetData,
  getUsersData,
} from '../../modules/userDetails/action';
import {
  getSelfDetails,
  getDefinedUsers,
  isFetchingDefinedUsers,
  isEnablingUserCognito,
  isDisablingUserCognito,
  userEnabledSucess,
  userDisabledSucess,
  userDisabledFailure,
  userEnabledFailure,
  orgCodeFetchedData,
  resettingPassword,
  dataResetPassword,
  failureResetPassword,
  resettingDevice,
  dataResetDevice,
  failureResetDevice,
  updatingPortalAccess,
  downloadUsersData,
} from '../../modules/userDetails/selector';
import DataFilterBox from '../common/DataFilterBox';
import {userRoles} from '../home/Roles';
import {getTerritoryDetails} from '../../modules/territoryFilter/action';
import {getTerritoryFilterData} from '../../modules/territoryFilter/selectors';
import {
  RESETENABLED_SUCCESS,
  RESETDISABLED_SUCCESS,
} from '../../modules/userDetails/constants';
import {Parser} from 'json2csv';

const UserManagement = () => {
  const authUser = useSelector(getAuthUserData);
  const selfUserinfo = useSelector(getSelfDetails);
  const definedUsers = useSelector(getDefinedUsers);
  const userEnabling = useSelector(isEnablingUserCognito);
  const userDisabling = useSelector(isDisablingUserCognito);
  const userDisabledSucessfully = useSelector(userDisabledSucess);
  const userEnabledSucessfully = useSelector(userEnabledSucess);
  const userDisabledFailed = useSelector(userDisabledFailure);
  const userEnabledFailed = useSelector(userEnabledFailure);
  const territoryData = useSelector(getTerritoryFilterData);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  //const [isUserDealer, setIsUserDealer] = useState(false);
  const dispatch = useDispatch();
  const isFetchingUsers = useSelector(isFetchingDefinedUsers);
  const [data, setData] = useState(definedUsers);
  const [filteredRole, setFileteredRole] = useState('All');
  const [filteredOrgCode, setFileteredOrgCode] = useState('All');
  const [filteredName, setFilteredName] = useState('');
  const [filteredEmpId, setFileteredEmpId] = useState('');
  const [tmpRegionArray, setTmpRegionArray] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const orgCodeData = useSelector(orgCodeFetchedData);
  const passwordResetting = useSelector(resettingPassword);
  const passwordResetData = useSelector(dataResetPassword);
  const failureResetPasswordData = useSelector(failureResetPassword);
  const devcieResetting = useSelector(resettingDevice);
  const deviceResetData = useSelector(dataResetDevice);
  const failureResetDeviceData = useSelector(failureResetDevice);
  const [resetPasswordClick, setResetPasswordClick] = useState(false);
  const userData = useSelector(downloadUsersData);
  const isUpdatingPortalAccess = useSelector(updatingPortalAccess);

  useEffect(() => {
    dispatch(getUsersDefined(authUser.accessToken));
    dispatch(getTerritoryDetails(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    dispatch(getUsersData(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    setIsUserAdmin(
      selfUserinfo
        ? selfUserinfo.roles.length > 0
          ? selfUserinfo.roles.includes('Admin') ||
            selfUserinfo.roles.includes('THCM Employee')
          : false
        : false,
    );
    /*setIsUserDealer(
      selfUserinfo
        ? selfUserinfo.roles.length > 0
          ? selfUserinfo.roles.includes('Dealer')
          : false
        : false,
    );*/
  }, [selfUserinfo]);

  const approvedClicked = (userApprove, record) => {
    if (userApprove) {
      dispatch(enableUser(record.id, authUser.accessToken));
    } else {
      dispatch(disableUser(record.id, authUser.accessToken));
    }
  };

  const onResetDeviceClick = (value, record) => {
    dispatch(resetDevice(record.id, authUser.accessToken));
  };
  useEffect(() => {
    if (userDisabledSucessfully) {
      dispatch(resetData(RESETDISABLED_SUCCESS));
      message.success({
        content: 'User disabled successfully!!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
    }
  }, [userDisabledSucessfully, dispatch]);

  useEffect(() => {
    if (userDisabledFailed || userEnabledFailed) {
      message.error({
        content: 'Something went wrong!!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
    }
  }, [userEnabledFailed, userDisabledFailed]);

  useEffect(() => {
    if (userEnabledSucessfully) {
      dispatch(resetData(RESETENABLED_SUCCESS));
      message.success({
        content: 'User enabled successfully!!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
    }
  }, [userEnabledSucessfully, dispatch]);

  const onRoleChanged = (e) => {
    setFileteredRole(e);
  };
  const onOrgCodeChanged = (e) => {
    setFileteredOrgCode(e);
  };
  const onUserNameChange = ({target}) => {
    setFilteredName(target.value.trim());
  };
  const onEmployeeCodeChange = ({target}) => {
    setFileteredEmpId(target.value.trim());
  };

  const onResetPasswordClick = (value, record) => {
    dispatch(resetUserPassword(authUser.accessToken, record.id));
    setResetPasswordClick(!resetPasswordClick);
  };

  useEffect(() => {
    if (resetPasswordClick && passwordResetData && !passwordResetting) {
      setResetPasswordClick(!resetPasswordClick);
      Modal.success({
        title: 'Password Reset Success!!',
        content: (
          <div>
            <p>
              New Passsword:<b> {passwordResetData.changedPassword}</b>
            </p>
            <p>
              {passwordResetData.mailSentSuccessfully
                ? 'Alternatively an email with the new password has been sent to the user !!'
                : 'Invalid Email Address or problem in sending the email !!'}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }, [resetPasswordClick, passwordResetData, passwordResetting]);

  const onResetDataClick = () => {
    setData(
      definedUsers
        ? definedUsers.map((item) => ({
            ...item,
            name: `${item.firstName} ${item.lastName}`,
          }))
        : definedUsers,
    );
    setFileteredRole('All');
    setFileteredOrgCode('All');
    setFilteredName('');
    setFileteredEmpId('');
  };

  const onExportUserDataClick = () => {
    callApiAndDownloadCsv({});
  };

  const callApiAndDownloadCsv = async () => {
    if (userData) {
      const headers = Object.keys(userData[0]);

      const parser = new Parser(headers);
      const csv = parser.parse(userData);
      var blob = new Blob([csv], {type: 'text/csv'});
      if (window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveBlob(blob, `Users_Data_${new Date()}.csv`);
      else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
        a.download = `Users_Data_${new Date()}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  };

  const items = [
    {
      fieldId: 1,
      fieldName: 'Filter By Name',
      fieldTitle: '',
      fieldMargin: '3px',
      fieldType: 'Label',
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '',
      placeholderTitle: 'Search by User Name',
      onChange: onUserNameChange,
      value: filteredName,
    },
    {
      fieldId: 4,
      fieldType: 'Col',
    },
    {
      fieldId: 5,
      fieldName: 'Filter By Employee Id',
      fieldTitle: '',
      fieldMargin: '3px',
      fieldType: 'Label',
    },
    {
      fieldId: 6,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '',
      placeholderTitle: 'Search by Employee Id',
      onChange: onEmployeeCodeChange,
      value: filteredEmpId,
    },
    {
      fieldId: 7,
      fieldType: 'Col',
    },
    {
      fieldId: 8,
      fieldName: 'Filter By Role',
      fieldTitle: '',
      fieldType: 'Label',
      fieldMargin: '3px',
    },
    {
      fieldId: 9,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Select',
      fieldMargin: '',
      options: userRoles,
      onChange: onRoleChanged,
      value: filteredRole,
    },
    {
      fieldId: 7,
      fieldType: 'Col',
    },
    {
      fieldId: 10,
      fieldName: 'Filter By Organisation Code',
      fieldTitle: '',
      fieldMargin: '18px',
      fieldType: 'Label',
    },
    {
      fieldId: 11,
      fieldName: '',
      fieldTitle: '',
      fieldMargin: '15px',
      fieldType: 'Select',
      options: orgCodeData,
      onChange: onOrgCodeChanged,
      value: filteredOrgCode,
    },
    {
      fieldId: 12,
      fieldName: 'Reset',
      fieldTitle: '',
      fieldMargin: '15px',
      fieldType: 'Button',
      onClick: onResetDataClick,
    },
    {
      fieldId: 13,
      fieldName: 'Export User Data',
      fieldTitle: '',
      fieldMargin: '15px',
      fieldType: 'Button',
      onClick: onExportUserDataClick,
    },
  ];

  const approvedAppAccess = (e, record) => {
    if (!record.isSyncedInCognito) {
      message.info({
        content: 'You can give access only to approved user!!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      return;
    }
    dispatch(
      updateAccess(record.id, authUser.accessToken, e, record.canAccessPortal),
    );
  };

  const approvedPortalAccess = (e, record) => {
    if (!record.isSyncedInCognito) {
      message.info({
        content: 'You can give access only to approved user!!',
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      });
      return;
    }
    dispatch(
      updateAccess(record.id, authUser.accessToken, record.canAccessApp, e),
    );
  };

  const handleDropDownClicks = (value, record, rowName) => {
    let key = rowName === 'regionList' ? 'regionId' : 'branchId';
    let valueId = null;
    let tmpData = JSON.parse(JSON.stringify(data));
    if (rowName === 'regionList') {
      territoryData.regions.forEach((region) => {
        if (region.name === value) valueId = region.id;
      });
    } else if (rowName === 'branchList') {
      territoryData.branches.forEach((branch) => {
        if (branch.name === value) valueId = branch.id;
      });
    }
    if (valueId) {
      tmpData.forEach((row) => {
        if (row.id === record.id) {
          if (row[rowName]) {
            if (row[rowName].includes(valueId)) alert('value already selected');
            else {
              row[rowName] = [...row[rowName], valueId];
              dispatch(
                addRegionManager(
                  authUser.accessToken,
                  {
                    [key]: valueId,
                    userId: record.id,
                  },
                  rowName,
                ),
              );
            }
          } else {
            row[rowName] = [valueId];
            dispatch(
              addRegionManager(
                authUser.accessToken,
                {
                  [key]: valueId,
                  userId: record.id,
                },
                rowName,
              ),
            );
          }
          // return true;
        }
      });
      setData(tmpData);
    }
  };
  const handleDropDownDeselect = (value, record, rowName) => {
    let key = rowName === 'regionList' ? 'regionId' : 'branchId';
    let valueId = null;
    let tmpData = JSON.parse(JSON.stringify(data));
    if (rowName === 'regionList') {
      territoryData.regions.forEach((region) => {
        if (region.name === value) valueId = region.id;
      });
    } else if (rowName === 'branchList') {
      territoryData.branches.forEach((branch) => {
        if (branch.name === value) valueId = branch.id;
      });
    }
    if (valueId) {
      tmpData.forEach((row) => {
        if (row.id === record.id) {
          dispatch(
            deleteRegionManager(
              authUser.accessToken,
              {
                [key]: valueId,
                userId: record.id,
              },
              rowName,
            ),
          );
          row[rowName].splice(row[rowName].indexOf(valueId), 1);
          // return true;
        }
      });
      setData(tmpData);
    }
  };

  const updatedColumns = !isUserAdmin
    ? [...userInfoColumns]
    : [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'email',
          sorter: {
            compare: (a, b) => a.name.localeCompare(b.name),
          },
        },
        {
          title: 'Employee Id',
          dataIndex: 'employeeCode',
          key: 'email',
          sorter: {
            compare: (a, b) => a.name.localeCompare(b.name),
          },
        },
        {
          title: 'Regions',
          dataIndex: 'regionList',
          key: 'regionList',
          width: '120pt',
          render: (regionArray, record) => {
            let value = [];
            territoryData.regions.forEach((region) => {
              if (regionArray?.includes(region.id)) {
                value = [...value, region.name];
              }
            });

            let isDisabled = record?.branchList.length > 0 ? true : false;
            return (
              <Select
                disabled={isDisabled}
                maxTagCount="2"
                mode="multiple"
                onDeselect={(val) => {
                  handleDropDownDeselect(val, record, 'regionList');
                }}
                onSelect={(val) => {
                  handleDropDownClicks(val, record, 'regionList');
                }}
                placeholder="Select Regions"
                size={'small'}
                style={{width: '100%'}}
                value={value}>
                {territoryData?.regions?.map((row, index) => {
                  return (
                    <Select.Option value={row.name}>{row.name}</Select.Option>
                  );
                })}
              </Select>
            );
          },
        },
        {
          title: 'Branches',
          dataIndex: 'branchList',
          key: 'branchList',
          width: '120pt',
          render: (branchArray, record) => {
            let value = [];
            territoryData.branches.forEach((branch) => {
              if (branchArray?.includes(branch.id)) {
                value = [...value, branch.name];
              }
            });
            let isDisabled = record?.regionList.length > 0 ? true : false;
            return (
              <Select
                defaultValue={value}
                disabled={isDisabled}
                maxTagCount="2"
                mode="multiple"
                onDeselect={(val) => {
                  handleDropDownDeselect(val, record, 'branchList');
                }}
                onSelect={(val) => {
                  handleDropDownClicks(val, record, 'branchList');
                }}
                placeholder="Select Branches"
                size={'small'}
                style={{width: '100%'}}
                value={value}>
                {territoryData?.branches?.map((row, index) => {
                  return (
                    <Select.Option value={row.name}>{row.name}</Select.Option>
                  );
                })}
              </Select>
            );
          },
        },
        {
          title: 'Organisation Code',
          dataIndex: 'orgCode',
          key: 'email',
        },
        {
          title: 'Logged In Device',
          dataIndex: 'deviceList',
          key: 'email',
        },
        {
          title: 'Role',
          dataIndex: 'roles',
          key: 'email',
        },
        {
          title: 'Email Address',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone Number',
          dataIndex: 'mobileNumber',
          key: 'email',
          sorter: {
            compare: (a, b) => a.mobileNumber / b.mobileNumber,
          },
        },
        {
          title: 'Approve User',
          dataIndex: 'action',
          key: 'email',
          width: '12%',
          render: (text, record) => (
            <Switch
              checked={record.isSyncedInCognito}
              onChange={(e) => approvedClicked(e, record)}
            />
          ),
        },
        {
          title: 'Reset Password',
          dataIndex: 'action',
          key: 'email',
          width: '12%',
          render: (text, record) => (
            <>
              <Button
                disabled={!record.isSyncedInCognito}
                onClick={(e) => {
                  onResetPasswordClick(e, record);
                }}
                style={{
                  background: !record.isSyncedInCognito
                    ? '#cccccc'
                    : '#fd560eb5',
                  border: !record.isSyncedInCognito ? '#cccccc' : '#fd560eb5',
                  fontWeight: '550',
                }}
                type="primary">
                Reset Password
              </Button>
            </>
          ),
        },
        {
          title: 'Reset Device',
          dataIndex: 'action',
          key: 'email',
          width: '12%',
          render: (text, record) => (
            <>
              <Button
                disabled={
                  record.deviceList ? !record.deviceList.length > 0 : false
                }
                onClick={(e) => {
                  onResetDeviceClick(e, record);
                }}
                style={{
                  background: record.deviceList
                    ? !record.deviceList.length > 0
                      ? '#cccccc'
                      : '#fd560eb5'
                    : '#cccccc',
                  border: record.deviceList
                    ? !record.deviceList.length > 0
                      ? '#cccccc'
                      : '#fd560eb5'
                    : '#cccccc',
                  fontWeight: '550',
                }}
                type="primary">
                Reset Device
              </Button>
            </>
          ),
        },
        {
          title: 'CanAccessApp',
          dataIndex: 'action',
          key: 'email',
          render: (text, record) => (
            <>
              <Switch
                checked={record.canAccessApp && record.isSyncedInCognito}
                onChange={(e) => approvedAppAccess(e, record)}
              />
            </>
          ),
        },
        {
          title: 'CanAccessPortal',
          dataIndex: 'action',
          key: 'email',
          render: (text, record) => (
            <>
              <Switch
                checked={record.canAccessPortal && record.isSyncedInCognito}
                onChange={(e) => approvedPortalAccess(e, record)}
              />
            </>
          ),
        },
        ...userInfoColumns,
      ];

  useEffect(() => {
    setData(
      definedUsers
        ? definedUsers.map((item) => ({
            ...item,
            name: `${item.firstName} ${item.lastName}`,
          }))
        : definedUsers,
    );
  }, [definedUsers]);

  useEffect(() => {
    const filterRoleItems = (item) => {
      return item.roles.includes(',')
        ? item.roles.split(',').filter((role) => role === filteredRole).length >
            0
        : item.roles === filteredRole;
    };
    const filterOrgCodeItems = (item) => {
      return item.orgCode
        ? item.orgCode
            .trim()
            .toLowerCase()
            .includes(filteredOrgCode?.trim().toLowerCase())
        : false;
    };
    const filterUserNameItems = (item) => {
      return item.name
        .trim()
        .toLowerCase()
        .includes(filteredName.trim().toLowerCase());
    };
    const filterEmployeeItems = (item) => {
      return item.employeeCode
        .trim()
        .toLowerCase()
        .includes(filteredEmpId.trim().toLowerCase());
    };
    if (data) {
      if (filteredRole === 'All' && filteredOrgCode === 'All') {
        if (filteredName !== '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterUserNameItems(item))
              .filter((item) => filterEmployeeItems(item)),
          );
        } else if (filteredName === '' && filteredEmpId !== '') {
          setFilteredData(data.filter((item) => filterEmployeeItems(item)));
        } else if (filteredName !== '' && filteredEmpId === '') {
          setFilteredData(data.filter((item) => filterUserNameItems(item)));
        } else {
          setFilteredData(data);
        }
      } else if (filteredRole === 'All' && filteredOrgCode !== 'All') {
        if (filteredName !== '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterOrgCodeItems(item))
              .filter((item) => filterUserNameItems(item))
              .filter((item) => filterEmployeeItems(item)),
          );
        } else if (filteredName === '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterOrgCodeItems(item))
              .filter((item) => filterEmployeeItems(item)),
          );
        } else if (filteredName !== '' && filteredEmpId === '') {
          setFilteredData(
            data
              .filter((item) => filterOrgCodeItems(item))
              .filter((item) => filterUserNameItems(item)),
          );
        } else {
          setFilteredData(data.filter((item) => filterOrgCodeItems(item)));
        }
      } else if (filteredRole !== 'All' && filteredOrgCode === 'All') {
        if (filteredName !== '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterUserNameItems(item))
              .filter((item) => filterEmployeeItems(item)),
          );
        } else if (filteredName === '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterEmployeeItems(item)),
          );
        } else if (filteredName !== '' && filteredEmpId === '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterUserNameItems(item)),
          );
        } else {
          setFilteredData(data.filter((item) => filterRoleItems(item)));
        }
      } else if (filteredRole !== 'All' && filteredOrgCode !== 'All') {
        if (filteredName !== '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterUserNameItems(item))
              .filter((item) => filterEmployeeItems(item))
              .filter((item) => filterOrgCodeItems(item)),
          );
        } else if (filteredName === '' && filteredEmpId !== '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterEmployeeItems(item))
              .filter((item) => filterOrgCodeItems(item)),
          );
        } else if (filteredName !== '' && filteredEmpId === '') {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterUserNameItems(item))
              .filter((item) => filterOrgCodeItems(item)),
          );
        } else {
          setFilteredData(
            data
              .filter((item) => filterRoleItems(item))
              .filter((item) => filterOrgCodeItems(item)),
          );
        }
      }
    }
  }, [filteredRole, filteredName, filteredEmpId, filteredOrgCode, data]);

  return (
    <MainLayout>
      <DataFilterBox items={items} title="User Filter"></DataFilterBox>
      <div className="contentLayout">
        <span className="contentHeader">User Management</span>
        <Table
          columns={updatedColumns}
          dataSource={filteredData}
          loading={
            isFetchingUsers ||
            userEnabling ||
            userDisabling ||
            passwordResetting ||
            devcieResetting ||
            isUpdatingPortalAccess
          }
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};

export default UserManagement;
