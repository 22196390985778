import {createSelector} from 'reselect';

export const getUserInfo = ({userInfo}) => userInfo;
export const getSelfDetails = createSelector(
  getUserInfo,
  (userInfo) => userInfo.data,
);
export const getDefinedUsers = createSelector(
  getUserInfo,
  (userInfo) => userInfo.definedUsersData,
);
export const isFetchedUserInfo = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isFetchingUser,
);
export const isFetchingDefinedUsers = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isFetchingDefinedUsers,
);
export const isUserEnabled = createSelector(
  getUserInfo,
  (userInfo) => userInfo.enabledUserData,
);

export const isUserDisabled = createSelector(
  getUserInfo,
  (userInfo) => userInfo.disbledUserData,
);

export const isEnablingUserCognito = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isEnablingUser,
);
export const isDisablingUserCognito = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isdisablingUser,
);
export const userEnabledSucess = createSelector(
  getUserInfo,
  (userInfo) => userInfo.hasUserEnabled,
);
export const userDisabledSucess = createSelector(
  getUserInfo,
  (userInfo) => userInfo.hasUserDisabled,
);

export const userDisabledFailure = createSelector(
  getUserInfo,
  (userInfo) => userInfo.failureUserDisable,
);
export const userEnabledFailure = createSelector(
  getUserInfo,
  (userInfo) => userInfo.failureUserEnable,
);

export const orgCodeFetchedData = createSelector(
  getUserInfo,
  (userInfo) => userInfo.orgCodeData,
);

export const resettingPassword = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isResettingPassword,
);
export const dataResetPassword = createSelector(
  getUserInfo,
  (userInfo) => userInfo.resetPasswordData,
);
export const failureResetPassword = createSelector(
  getUserInfo,
  (userInfo) => userInfo.failureResetPassword,
);
export const resettingDevice = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isResettingDevice,
);
export const dataResetDevice = createSelector(
  getUserInfo,
  (userInfo) => userInfo.resetDeviceData,
);
export const failureResetDevice = createSelector(
  getUserInfo,
  (userInfo) => userInfo.failureResetDevice,
);

export const checkingPortalAccess = createSelector(
  getUserInfo,
  (userInfo) => userInfo.checkPortalAccessRequest,
);

export const checkPortalAccessValue = createSelector(
  getUserInfo,
  (userInfo) => userInfo.checkPortalAccessData,
);

export const updatingPortalAccess = createSelector(
  getUserInfo,
  (userInfo) => userInfo.isUpdatingAccess,
);
export const updateAccessData = createSelector(
  getUserInfo,
  (userInfo) => userInfo.updateAccessData,
);

export const downloadUsersData = createSelector(
  getUserInfo,
  (userInfo) => userInfo.getUsersData,
);
