import {
  Users_FAILURE,
  Users_REQUEST,
  Users_SUCCESS,
  UsersDefined_FAILURE,
  UsersDefined_SUCCESS,
  UsersDefined_REQUEST,
  EnableUser_FAILURE,
  DisableUser_REQUEST,
  DisableUser_FAILURE,
  DisableUser_SUCCESS,
  EnableUser_REQUEST,
  EnableUser_SUCCESS,
  ORGCODE_REQUEST,
  ORGCODE_SUCCESS,
  ORGCODE_FAILURE,
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETDEVICE_FAILURE,
  RESETDEVICE_SUCCESS,
  RESETDEVICE_REQUEST,
  CHECKPORTALACCESS_REQUEST,
  CHECKPORTALACCESS_SUCCESS,
  CHECKPORTALACCESS_FAILURE,
  UPDATEACCESS_REQUEST,
  UPDATEACCESS_SUCCESS,
  UPDATEACCESS_FAILURE,
  RESETENABLED_SUCCESS,
  RESETDISABLED_SUCCESS,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const isFetchingUser = (state = false, action) => {
  switch (action.type) {
    case Users_REQUEST:
      return true;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case Users_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureUser = (state = null, action) => {
  switch (action.type) {
    case Users_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasUserFetched = (state = false, action) => {
  switch (action.type) {
    case Users_REQUEST:
      return false;
    case Users_SUCCESS:
    case Users_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingDefinedUsers = (state = false, action) => {
  switch (action.type) {
    case UsersDefined_REQUEST:
      return true;
    case UsersDefined_SUCCESS:
    case UsersDefined_FAILURE:
      return false;
    default:
      return state;
  }
};

const definedUsersData = (state = null, action) => {
  switch (action.type) {
    case UsersDefined_SUCCESS:
      return action.payload;
    case EnableUser_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.id) {
          return {
            ...user,
            isSyncedInCognito: true,
          };
        }
        return user;
      });
    case DisableUser_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.id) {
          return {
            ...user,
            isSyncedInCognito: false,
          };
        }
        return user;
      });
    case RESETDEVICE_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.userId) {
          return {
            ...user,
            deviceList: [],
          };
        }
        return user;
      });

    case UPDATEACCESS_SUCCESS:
      return state.map((user) => {
        if (user.id === action.payload.userId) {
          return {
            ...user,
            canAccessApp: action.payload.canAccessApp,
            canAccessPortal: action.payload.canAccessPortal,
          };
        }
        return user;
      });
    default:
      return state;
  }
};

const failureUsersDefined = (state = null, action) => {
  switch (action.type) {
    case UsersDefined_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDefinedUsersFetched = (state = false, action) => {
  switch (action.type) {
    case UsersDefined_REQUEST:
      return false;
    case UsersDefined_SUCCESS:
    case UsersDefined_FAILURE:
      return true;
    default:
      return state;
  }
};

const isEnablingUser = (state = false, action) => {
  switch (action.type) {
    case EnableUser_REQUEST:
      return true;
    case EnableUser_SUCCESS:
    case EnableUser_FAILURE:
      return false;
    default:
      return state;
  }
};

const enabledUserData = (state = null, action) => {
  switch (action.type) {
    case EnableUser_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureUserEnable = (state = false, action) => {
  switch (action.type) {
    case EnableUser_FAILURE:
      return true;
    default:
      return state;
  }
};

const hasUserEnabled = (state = false, action) => {
  switch (action.type) {
    case EnableUser_SUCCESS:
      return true;
    case RESETENABLED_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isdisablingUser = (state = false, action) => {
  switch (action.type) {
    case DisableUser_REQUEST:
      return true;
    case DisableUser_SUCCESS:
    case DisableUser_FAILURE:
      return false;
    default:
      return state;
  }
};

const disbledUserData = (state = null, action) => {
  switch (action.type) {
    case DisableUser_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureUserDisable = (state = false, action) => {
  switch (action.type) {
    case DisableUser_FAILURE:
      return true;
    default:
      return state;
  }
};

const hasUserDisabled = (state = false, action) => {
  switch (action.type) {
    case DisableUser_SUCCESS:
      return true;
    case RESETDISABLED_SUCCESS:
      return false;
    default:
      return state;
  }
};
const orgCodeData = (state = null, action) => {
  switch (action.type) {
    case ORGCODE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const orgCodeRequest = (state = false, action) => {
  switch (action.type) {
    case ORGCODE_REQUEST:
      return true;
    default:
      return state;
  }
};
const orgCodeFetched = (state = false, action) => {
  switch (action.type) {
    case ORGCODE_REQUEST:
      return false;
    case ORGCODE_FAILURE:
    case ORGCODE_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isResettingPassword = (state = false, action) => {
  switch (action.type) {
    case RESETPASSWORD_REQUEST:
      return true;
    case RESETPASSWORD_SUCCESS:
    case RESETPASSWORD_FAILURE:
      return false;
    default:
      return state;
  }
};

const resetPasswordData = (state = null, action) => {
  switch (action.type) {
    case RESETPASSWORD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureResetPassword = (state = false, action) => {
  switch (action.type) {
    case RESETPASSWORD_FAILURE:
      return true;
    default:
      return state;
  }
};

const hasPasswordResetted = (state = false, action) => {
  switch (action.type) {
    case RESETPASSWORD_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isResettingDevice = (state = false, action) => {
  switch (action.type) {
    case RESETDEVICE_REQUEST:
      return true;
    case RESETDEVICE_SUCCESS:
    case RESETDEVICE_FAILURE:
      return false;
    default:
      return state;
  }
};

const resetDeviceData = (state = null, action) => {
  switch (action.type) {
    case RESETDEVICE_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureResetDevice = (state = false, action) => {
  switch (action.type) {
    case RESETDEVICE_FAILURE:
      return true;
    default:
      return state;
  }
};

const hasResetDevice = (state = false, action) => {
  switch (action.type) {
    case RESETDEVICE_SUCCESS:
      return true;
    default:
      return state;
  }
};

const checkPortalAccessData = (state = null, action) => {
  switch (action.type) {
    case CHECKPORTALACCESS_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};
const checkPortalAccessRequest = (state = false, action) => {
  switch (action.type) {
    case CHECKPORTALACCESS_REQUEST:
      return true;
    case CHECKPORTALACCESS_SUCCESS:
    case CHECKPORTALACCESS_FAILURE:
      return false;
    default:
      return state;
  }
};
const checkPortalAccessFetched = (state = false, action) => {
  switch (action.type) {
    case CHECKPORTALACCESS_REQUEST:
      return false;
    case CHECKPORTALACCESS_FAILURE:
    case CHECKPORTALACCESS_SUCCESS:
      return true;
    default:
      return state;
  }
};
const isUpdatingAccess = (state = false, action) => {
  switch (action.type) {
    case UPDATEACCESS_REQUEST:
      return true;
    case UPDATEACCESS_SUCCESS:
    case UPDATEACCESS_FAILURE:
      return false;
    default:
      return state;
  }
};
const updateAccessData = (state = null, action) => {
  switch (action.type) {
    case UPDATEACCESS_SUCCESS:
      return action;
    default:
      return state;
  }
};

const failureUpdateData = (state = false, action) => {
  switch (action.type) {
    case UPDATEACCESS_FAILURE:
      return true;
    default:
      return state;
  }
};

const hasUpdateSuccess = (state = false, action) => {
  switch (action.type) {
    case UPDATEACCESS_SUCCESS:
      return true;
    default:
      return state;
  }
};

const getUsersData = (state = null, action) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return state;
    case USER_DATA_SUCCESS:
      return action.payload;
    case USER_DATA_FAILURE:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  hasUpdateSuccess,
  failureUpdateData,
  isUpdatingAccess,
  updateAccessData,
  checkPortalAccessData,
  checkPortalAccessRequest,
  checkPortalAccessFetched,
  isdisablingUser,
  hasUserDisabled,
  failureUserDisable,
  disbledUserData,
  isEnablingUser,
  hasUserEnabled,
  failureUserEnable,
  enabledUserData,
  isFetchingUser,
  data,
  failureUser,
  hasUserFetched,
  definedUsersData,
  hasDefinedUsersFetched,
  isFetchingDefinedUsers,
  failureUsersDefined,
  orgCodeData,
  orgCodeRequest,
  orgCodeFetched,
  isResettingPassword,
  resetPasswordData,
  failureResetPassword,
  hasPasswordResetted,
  isResettingDevice,
  resetDeviceData,
  failureResetDevice,
  hasResetDevice,
  getUsersData,
});
